window.addEventListener('DOMContentLoaded', function () {

  // Настройки для главного слайдера на index-ной странице
  $('#mainSlider').slick({
    autoplay: true,
    arrows:true,
    prevArrow: '<button type="button" class="slick-prev">←</button>',
    nextArrow: '<button type="button" class="slick-next">→</button>',
    dots: false,
    slidesToShow: 1,
    responsive: {}
  })










  

  // Выпадающие списки в блоке services
  const serviceItems = document.querySelectorAll('.services__top-item')

  for (let i = 0; i < serviceItems.length; i++) {
    serviceItems[i].addEventListener('click', toggleServiceItemsListHeight)
  }

  function toggleServiceItemsListHeight () {
    if (document.body.clientWidth <= 960) {
      if (this.classList.contains('services__top-item--active')) {
        this.classList.remove('services__top-item--active')
        this.style.marginBottom = 0
        this.querySelector('.top-item__links').style.height = 0
      } else {
        let listHeight = 0
        const listItems = this.querySelectorAll('.links__item')
        for (let i = 0; i < listItems.length; i++) {
          listHeight += listItems[i].getBoundingClientRect().height
        }
        this.style.marginBottom = listHeight + 'px'
        this.classList.add('services__top-item--active')
  
        this.querySelector('.top-item__links').style.height = listHeight + 'px'
      }
    }
  }









  // Обработка события ресайза ширины окна браузера
  window.addEventListener('resize', function () {
    // Устанавлиываем начальные параметры для элементов выпадающих списков блока сервисы
    if (document.body.clientWidth >= 960) {
      for (let i = 0; i < serviceItems.length;i++) {
        serviceItems[i].classList.remove('services__top-item--active')
        serviceItems[i].style.marginBottom = 0
        serviceItems[i].querySelector('.top-item__links').style.height = 'auto'
      }
    }
  })
})


// TODO: Выделить логику для события resize в отдельные функции для удобства чтения
