;
// console.log('maintanance')

// Обработка табов / выпадающих списков блока "Обслуживание 1С" (maintenance)
const tabs = document.querySelectorAll('.tab-header__element');
const tabContents = document.querySelectorAll('.tab-content__element');
const tabContentsWrapper = document.querySelector('.tab-contents');
const triangle = document.querySelector('.tab-headers__triangle');

// Устанавливаем начальное положение для треугольника, указывающего на активный таб для больших экранов
if (document.body.clientWidth >= 1150) {
    let activeTab = document.querySelector('.tab-header__element--active');
    triangle.style.left = activeTab.getBoundingClientRect().left + (activeTab.getBoundingClientRect().width / 2) + 'px';
    // Установка начальную высоту блока со скрытым контентом
    const targetElement = document.querySelector(`#${activeTab.getAttribute('data-target-content')}`);
    const targetElementHeight = targetElement.getBoundingClientRect().height;
    tabContentsWrapper.style.height = targetElementHeight + 60 + 'px';
}

for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', function () {
        // проверка обрабатывать событие как переключение табов или как аккордион
        if (document.body.clientWidth >= 1150) {
            // Логика для табов
            const targetHiddenContentId = '#' + this.getAttribute('data-target-content');
            // Убираем видимость для всех элементов скрытого контента табов
            for (let i = 0; i < tabContents.length; i++) {
                tabContents[i].style.display = 'none';
                tabs[i].classList.remove('tab-header__element--active');
            }
            // Устанавливаем видимость скрытого контента для выбранного таба
            const targetHiddenContentElement = document.querySelector(targetHiddenContentId);
            this.classList.add('tab-header__element--active');
            targetHiddenContentElement.style.display = 'block';

            // установка позиции для треугольника (указыает на активный таб)
            const newTrianglePosition = this.getBoundingClientRect().left + (this.getBoundingClientRect().width / 2);
            triangle.style.left = newTrianglePosition + 'px';

            // Установка высоты блока со скрытым контентом
            const targetElementHeight = targetHiddenContentElement.getBoundingClientRect().height;
            tabContentsWrapper.style.height = targetElementHeight + 60 + 'px';
        } else {
            // Логика для аккордиона вместо табов
            const accordionContent = this.querySelector('.element__accordion-content-wrapper');

            if (this.getAttribute('data-opened') === 'false') {
                this.style.marginBottom = accordionContent.getBoundingClientRect().height + 'px';
                accordionContent.style.opacity = 1;
                this.setAttribute('data-opened', 'true');
            } else {
                this.style.marginBottom = 0;
                this.setAttribute('data-opened', 'false');
                accordionContent.style.opacity = 0;
            }

        }
    })
}

const accordionElements = document.querySelectorAll('.tab-header__element');
if (document.body.clientWidth <= 1150) {
    // Убиаем класс активного таба для маленьких экранов
    for (let i = 0; i < accordionElements.length; i++) {
        accordionElements[i].classList.remove('tab-header__element--active');
    }
}

// Обработка события ресайза ширины окна браузера
window.addEventListener('resize', function () {
    // Устанавливается позиция треугольника, указывающего на активный таб
    if (document.body.clientWidth >= 1150) {
        // Устанавливаем активный класс для первого таба
        const accordionElements = document.querySelectorAll('.tab-header__element');
        accordionElements[0].classList.add('tab-header__element--active');

        const activeTab = document.querySelector('.tab-header__element--active');
        triangle.style.left = activeTab.getBoundingClientRect().left + (activeTab.getBoundingClientRect().width / 2) + 'px';

    } else {
        const accordionElements = document.querySelectorAll('.tab-header__element');
        for (let i = 0; i < accordionElements.length; i++) {
            accordionElements[i].classList.remove('tab-header__element--active');
        }
    }
});
